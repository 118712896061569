/**
 * Fichier comportant chaque catégorie de code inovert et les codes associées à ces catégories
 */

export const SearchFilters = { 
    CONFORME: [
        'SASCFM', 'COMCFM', 'LIVCFM', 'PODCFM', 'CHARGE', 'AARCFM', 'ECHCFM', 'EPCCFM', 'EMLARS', 'PCHCFM', 
        'DCHCFM', 'EXPCFM', 'CHGCFM', 'PAQCFM', 'QINCFM', 'QDICFM', 'RSDCFM', 'SEQCFM', 
        'QINDSP', 'QDIDSP', 'RSDDSP', 'PODDSP', 'COM', 'AFFRET', 'LIVREV'
    ] as const,
    NON_CONFORME : [
        'EPCNCF','EDICAD','EDIDIL','EDILDF','EDIPRE','EDIFCO','EDIFHB','EDIDAF','EDIDOC','EDINCG','EDIDDC','EDIDDR','ECHAVA',
        'ECHRCA','ECHCEX','ECHMQP','ECHOUV','ECHPVE','ECHVVE','ECHDDT','ECHDOC','ECHNCF','ECHRMA','ENEFCO','ENEFHB','ENEDAF',
        'ENECAD','ENEDIA','ENEDEM','ENELNA','ENEPCO','ENEAVI','ENENRV','ENEDOC','ENEAVA','ENETAR','ENENCF','ENEPVE','ENEVVE',
        'ENEDIV','ENEMQT','ENEMQP','ENENJU','ENEDDC','ENEDDR','ENEINT','ENEFMA','ENEPAM','ENERMA','PCHTAR','PCHNCF','PCHAVA',
        'PCHRCA','PCHCEX','PCHMQP','PCHMQT','PCHOUV','PCHPVE','PCHVVE','PCHDOC','PCHDDT','PCHRGT','PCHDVY','PCHRMA','AARTAR',
        'AARNCF','AARAVA','AARRCA','AARCEX','AARMQP','AARMQT','AAROUV','AARPVE','AARVVE','AARDOC','AARDDT','AARRGT','AARDVY',
        'AARRMA','DCHTAR','DCHNCF','DCHAVA','DCHRCA','DCHCEX','DCHMQP','DCHMQT','DCHOUV','DCHPVE','DCHVVE','DCHDOC','DCHDDT',
        'DCHRGT','DCHDVY','DCHRMA','EXPRCA','EXPCEX','EXPMQP','EXPDOC','EXPRMA','MLVRCA','MLVCEX','MLVMQP','MLVDOC','MLVRMA',
        'CHGRCA','CHGCEX','CHGMQP','CHGDOC','CHGRMA','DIFBRT','DIFAVA','DIFCEX','DIFMQP','DIFMQT','DIFCAD','DIFDIL','DIFLDF',
        'DIFPRE','DIFFCO','DIFFHB','DIFDAF','DIFDOC','DIFNCG','DIFDDC','DIFDDR','DIFRCA','DIFLPT','DIFINT','DIFFMA','DIFPAM',
        'DIFRAM','RSTBRT','RSTAVA','RSTCEX','RSTMQP','RSTMQT','RSTCAD','RSTDIL','RSTLDF','RSTPRE','RSTFCO','RSTFHB','RSTDAF',
        'RSTDOC','RSTNCG','RSTDDC','RSTDDR','RSTRCA','RSTLPT','RSTINT','RSTFMA','RSTPAM','RSTRAM','RAQBRT','RAQAVA','RAQCEX',
        'RAQMQP','RAQMQT','RAQCAD','RAQDIL','RAQLDF','RAQPRE','RAQFCO','RAQFHB','RAQDAF','RAQDOC','RAQNCG','RAQDDC','RAQDDR',
        'RAQRCA','RAQLPT','RAQINT','RAQFMA','RAQPAM','RAQRAM','LIVRCA','LIVMQP','LIVSPO','LIVDIV','LIVRSY','LIVDOC','LIVRMA',
        'LIVNEM','LIVARS','PAQRCA','PAQMQP','PAQSPO','PAQDIV','PAQRSY','PAQDOC','PAQRMA','PAQNEM','PAQARS','RENFCO','RENFHB',
        'RENDAF','RENCAD','RENDIA','RENDEM','RENLNA','RENPCO','RENAVI','RENNRV','RENDOC','RENMQP','RENAVA','RENSPD','RENSRB',
        'RENSNC','RENTAR','RENSPO','RENDIV','RENSPL','RENNJU','RENINT','RENFMA','RENJU','RENRMA','SOLMAR','SOLMQD','SOLANN',
        'SOLDOS','SOLREI','SOLREO','SOLRXD','SOLDDR','QINNID','QINMQT','QDICFM','QDINID','QDIMQT','LITCFM','LITNID','LITMQT',
        'LITDSP','RSDNID','RSDMQT','PODNID','PODMQT','SEQMQP','SEQMQT','SEQAVA','SEQNRD','LIVROQ','RSTPNT'
    ] as const,    
    SANS_STATUT: ['SANS STATUT', 'RSTPVI'] as const,
    MISE_EN_LIVRAISON: ['MLVCFM', 'EMLCFM'] as const,
    TOTAL : [
        'SASCFM', 'COMCFM', 'LIVCFM', 'PODCFM', 'CHARGE', 'AARCFM', 'ECHCFM', 'EPCCFM', 'EMLARS', 'PCHCFM', 
        'DCHCFM', 'EXPCFM', 'CHGCFM', 'PAQCFM', 'QINCFM', 'QDICFM', 'RSDCFM', 'SEQCFM', 
        'QINDSP', 'QDIDSP', 'RSDDSP', 'PODDSP', 'COM', 'AFFRET', 'LIVREV',
        'EPCNCF','EDICAD','EDIDIL','EDILDF','EDIPRE','EDIFCO','EDIFHB','EDIDAF','EDIDOC','EDINCG','EDIDDC','EDIDDR','ECHAVA',
        'ECHRCA','ECHCEX','ECHMQP','ECHOUV','ECHPVE','ECHVVE','ECHDDT','ECHDOC','ECHNCF','ECHRMA','ENEFCO','ENEFHB','ENEDAF',
        'ENECAD','ENEDIA','ENEDEM','ENELNA','ENEPCO','ENEAVI','ENENRV','ENEDOC','ENEAVA','ENETAR','ENENCF','ENEPVE','ENEVVE',
        'ENEDIV','ENEMQT','ENEMQP','ENENJU','ENEDDC','ENEDDR','ENEINT','ENEFMA','ENEPAM','ENERMA','PCHTAR','PCHNCF','PCHAVA',
        'PCHRCA','PCHCEX','PCHMQP','PCHMQT','PCHOUV','PCHPVE','PCHVVE','PCHDOC','PCHDDT','PCHRGT','PCHDVY','PCHRMA','AARTAR',
        'AARNCF','AARAVA','AARRCA','AARCEX','AARMQP','AARMQT','AAROUV','AARPVE','AARVVE','AARDOC','AARDDT','AARRGT','AARDVY',
        'AARRMA','DCHTAR','DCHNCF','DCHAVA','DCHRCA','DCHCEX','DCHMQP','DCHMQT','DCHOUV','DCHPVE','DCHVVE','DCHDOC','DCHDDT',
        'DCHRGT','DCHDVY','DCHRMA','EXPRCA','EXPCEX','EXPMQP','EXPDOC','EXPRMA','MLVRCA','MLVCEX','MLVMQP','MLVDOC','MLVRMA',
        'CHGRCA','CHGCEX','CHGMQP','CHGDOC','CHGRMA','DIFBRT','DIFAVA','DIFCEX','DIFMQP','DIFMQT','DIFCAD','DIFDIL','DIFLDF',
        'DIFPRE','DIFFCO','DIFFHB','DIFDAF','DIFDOC','DIFNCG','DIFDDC','DIFDDR','DIFRCA','DIFLPT','DIFINT','DIFFMA','DIFPAM',
        'DIFRAM','RSTBRT','RSTAVA','RSTCEX','RSTMQP','RSTMQT','RSTCAD','RSTDIL','RSTLDF','RSTPRE','RSTFCO','RSTFHB','RSTDAF',
        'RSTDOC','RSTNCG','RSTDDC','RSTDDR','RSTRCA','RSTLPT','RSTINT','RSTFMA','RSTPAM','RSTRAM','RAQBRT','RAQAVA','RAQCEX',
        'RAQMQP','RAQMQT','RAQCAD','RAQDIL','RAQLDF','RAQPRE','RAQFCO','RAQFHB','RAQDAF','RAQDOC','RAQNCG','RAQDDC','RAQDDR',
        'RAQRCA','RAQLPT','RAQINT','RAQFMA','RAQPAM','RAQRAM','LIVRCA','LIVMQP','LIVSPO','LIVDIV','LIVRSY','LIVDOC','LIVRMA',
        'LIVNEM','LIVARS','PAQRCA','PAQMQP','PAQSPO','PAQDIV','PAQRSY','PAQDOC','PAQRMA','PAQNEM','PAQARS','RENFCO','RENFHB',
        'RENDAF','RENCAD','RENDIA','RENDEM','RENLNA','RENPCO','RENAVI','RENNRV','RENDOC','RENMQP','RENAVA','RENSPD','RENSRB',
        'RENSNC','RENTAR','RENSPO','RENDIV','RENSPL','RENNJU','RENINT','RENFMA','RENJU','RENRMA','SOLMAR','SOLMQD','SOLANN',
        'SOLDOS','SOLREI','SOLREO','SOLRXD','SOLDDR','QINNID','QINMQT','QDICFM','QDINID','QDIMQT','LITCFM','LITNID','LITMQT',
        'LITDSP','RSDNID','RSDMQT','PODNID','PODMQT','SEQMQP','SEQMQT','SEQAVA','SEQNRD', 'LIVROQ','RSTPNT',
        'SANS STATUT', 'RSTPVI',
        'MLVCFM', 'EMLCFM'
    ] as const,
    TOTAL_HORS_SS_MEL: [
        'SASCFM', 'COMCFM', 'LIVCFM', 'PODCFM', 'CHARGE', 'AARCFM', 'ECHCFM', 'EPCCFM', 'EMLARS', 'PCHCFM', 
        'DCHCFM', 'EXPCFM', 'CHGCFM', 'PAQCFM', 'QINCFM', 'QDICFM', 'RSDCFM', 'SEQCFM', 
        'QINDSP', 'QDIDSP', 'RSDDSP', 'PODDSP','COM', 'AFFRET', 'LIVREV',
        'EPCNCF','EDICAD','EDIDIL','EDILDF','EDIPRE','EDIFCO','EDIFHB','EDIDAF','EDIDOC','EDINCG','EDIDDC','EDIDDR','ECHAVA',
        'ECHRCA','ECHCEX','ECHMQP','ECHOUV','ECHPVE','ECHVVE','ECHDDT','ECHDOC','ECHNCF','ECHRMA','ENEFCO','ENEFHB','ENEDAF',
        'ENECAD','ENEDIA','ENEDEM','ENELNA','ENEPCO','ENEAVI','ENENRV','ENEDOC','ENEAVA','ENETAR','ENENCF','ENEPVE','ENEVVE',
        'ENEDIV','ENEMQT','ENEMQP','ENENJU','ENEDDC','ENEDDR','ENEINT','ENEFMA','ENEPAM','ENERMA','PCHTAR','PCHNCF','PCHAVA',
        'PCHRCA','PCHCEX','PCHMQP','PCHMQT','PCHOUV','PCHPVE','PCHVVE','PCHDOC','PCHDDT','PCHRGT','PCHDVY','PCHRMA','AARTAR',
        'AARNCF','AARAVA','AARRCA','AARCEX','AARMQP','AARMQT','AAROUV','AARPVE','AARVVE','AARDOC','AARDDT','AARRGT','AARDVY',
        'AARRMA','DCHTAR','DCHNCF','DCHAVA','DCHRCA','DCHCEX','DCHMQP','DCHMQT','DCHOUV','DCHPVE','DCHVVE','DCHDOC','DCHDDT',
        'DCHRGT','DCHDVY','DCHRMA','EXPRCA','EXPCEX','EXPMQP','EXPDOC','EXPRMA','MLVRCA','MLVCEX','MLVMQP','MLVDOC','MLVRMA',
        'CHGRCA','CHGCEX','CHGMQP','CHGDOC','CHGRMA','DIFBRT','DIFAVA','DIFCEX','DIFMQP','DIFMQT','DIFCAD','DIFDIL','DIFLDF',
        'DIFPRE','DIFFCO','DIFFHB','DIFDAF','DIFDOC','DIFNCG','DIFDDC','DIFDDR','DIFRCA','DIFLPT','DIFINT','DIFFMA','DIFPAM',
        'DIFRAM','RSTBRT','RSTAVA','RSTCEX','RSTMQP','RSTMQT','RSTCAD','RSTDIL','RSTLDF','RSTPRE','RSTFCO','RSTFHB','RSTDAF',
        'RSTDOC','RSTNCG','RSTDDC','RSTDDR','RSTRCA','RSTLPT','RSTINT','RSTFMA','RSTPAM','RSTRAM','RAQBRT','RAQAVA','RAQCEX',
        'RAQMQP','RAQMQT','RAQCAD','RAQDIL','RAQLDF','RAQPRE','RAQFCO','RAQFHB','RAQDAF','RAQDOC','RAQNCG','RAQDDC','RAQDDR',
        'RAQRCA','RAQLPT','RAQINT','RAQFMA','RAQPAM','RAQRAM','LIVRCA','LIVMQP','LIVSPO','LIVDIV','LIVRSY','LIVDOC','LIVRMA',
        'LIVNEM','LIVARS','PAQRCA','PAQMQP','PAQSPO','PAQDIV','PAQRSY','PAQDOC','PAQRMA','PAQNEM','PAQARS','RENFCO','RENFHB',
        'RENDAF','RENCAD','RENDIA','RENDEM','RENLNA','RENPCO','RENAVI','RENNRV','RENDOC','RENMQP','RENAVA','RENSPD','RENSRB',
        'RENSNC','RENTAR','RENSPO','RENDIV','RENSPL','RENNJU','RENINT','RENFMA','RENJU','RENRMA','SOLMAR','SOLMQD','SOLANN',
        'SOLDOS','SOLREI','SOLREO','SOLRXD','SOLDDR','QINNID','QINMQT','QDICFM','QDINID','QDIMQT','LITCFM','LITNID','LITMQT',
        'LITDSP','RSDNID','RSDMQT','PODNID','PODMQT','SEQMQP','SEQMQT','SEQAVA','SEQNRD', 'LIVROQ','RSTPNT'
    ] as const,
};





    
