import { Injectable } from '@angular/core';
import { HttpService } from './http-client.service';
import { DeliveryReportShare } from '../models/delivery_report';
import { CsvGraph } from '../models/csv-graph';
import { DelayReportShare } from '../models/retard_response';

@Injectable({
  providedIn: 'root'
})
export class CsvService {

  constructor(private httpService: HttpService) { }

  /** ENG
  * Sends a CSV file via HTTP and returns an upload status.
  * 
  * @param fileData The CSV file data to send.
  * @returns An upload status indicating when the operation is successfully completed.
  */
  /** FR
  * Envoie un fichier CSV via HTTP et retourne un suivi de l'envoi.
  * 
  * @param fileData Les données du fichier CSV à envoyer.
  * @returns Un suivi de l'envoi qui indique quand l'opération est terminée avec succès.
  */
  sendCSVFile(fileData: FormData) {
    return this.httpService.sendCSVFile(fileData);
  }

  /** ENG
   * Sends a delivery report via HTTP and returns a report sharing status.
   * 
   * @param delivery_report_share The delivery report data to share.
   * @returns A status indicating when the sharing operation is successfully completed.
   */
  /** FR
  * Envoie un rapport de livraison via HTTP et retourne un statut de partage du rapport.
  * 
  * @param delivery_report_share Les données du rapport de livraison à partager.
  * @returns Un statut indiquant quand l'opération de partage est terminée avec succès.
  */
  shareDeliveryReport(delivery_report_share: DeliveryReportShare) {
    return this.httpService.shareDeliveryReport(delivery_report_share);
  }

  /** ENG
   * Sends a delay report via HTTP and returns a report sharing status.
   * 
   * @param delay_report_share The delay report data to share.
   * @returns A status indicating when the sharing operation is successfully completed.
   */
  /** FR
  * Envoie un rapport de retard via HTTP et retourne un statut de partage du rapport.
  * 
  * @param delay_report_share Les données du rapport de retard à partager.
  * @returns Un statut indiquant quand l'opération de partage est terminée avec succès.
  */
  shareDelayReport(delay_report_share: DelayReportShare) {
    return this.httpService.shareDelayReport(delay_report_share);
  }


  /** ENG
  * Shares a graph CSV via HTTP and returns a sharing status.
  * 
  * @param csv_graph The graph CSV data to share.
  * @returns A status indicating when the sharing operation is successfully completed.
  */
  /** FR
  * Partage un graphique CSV via HTTP et retourne un statut de partage.
  * 
  * @param csv_graph Les données du graphique CSV à partager.
  * @returns Un statut indiquant quand l'opération de partage est terminée avec succès.
  */
  shareGraphCSV(csv_graph: CsvGraph) {
    return this.httpService.shareGraphCSV(csv_graph);
  }
}
