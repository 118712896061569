import { Component, OnInit } from '@angular/core';
import { InitializationService } from './core/services/initialization.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent implements OnInit {
  title = 'KPI';

  constructor(
    private initializationService: InitializationService,
  ){}

  ngOnInit(): void {
    this.initializationService.initServices();
  }
  
}
