import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  private viewmodalSubject = new BehaviorSubject<boolean>(false);
  viewmodal$: Observable<boolean> = this.viewmodalSubject.asObservable();

  savefirstmodal() {
    localStorage.setItem('alreadyco', 'true');
    const newValue = true;
    this.viewmodalSubject.next(newValue);
  }
}
