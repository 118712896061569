import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FilterOption } from '../models/delivery_report';

@Injectable({
    providedIn: 'root'
})
export class SearchfilterService {

    // Filter options for dropdown
    // Options de filtrage pour le dropdown
    dropdownList: FilterOption[] = [
        { id: 'numRecep', name: 'Numéro dossier' },
        { id: 'refClient', name: 'Référence Client' },
        { id: 'inovert', name: 'Statut' },
        { id: 'dateRecep', name: 'Date de réception' },
        { id: 'expNom', name: 'Nom d\'expéditeur' },
        { id: 'expVille', name: 'Ville de l\'expéditeur' },
        { id: 'expCP', name: 'CP de l\'expéditeur' },
        { id: 'destinataire', name: 'Nom du destinataire' },
        { id: 'localite', name: 'Ville du destinataire' },
        { id: 'cp', name: 'CP du destinataire' },
        { id: 'retard', name: 'Temps de retard' },
        { id: 'poids', name: 'Poids' },
        { id: 'co2', name: 'Taux de Co2' }
    ];

    // The currently selected filter option
    // L'option de filtre actuellement sélectionnée
    selectedFilter!: FilterOption;

    // Subject behavior to manage the state of the selected elemen
    // Comportement subject pour gérer l'état de l'élément sélectionné
    private selectedItemSubject = new BehaviorSubject<FilterOption | null>(null);

    // Observable to listen for changes in the selected element
    // Observable pour écouter les changements de l'élément sélectionné
    selectedItem$ = this.selectedItemSubject.asObservable();

    private tagsSubject = new BehaviorSubject<string[]>([]);
    tags$ = this.tagsSubject.asObservable();


    /**
     * Deselects the currently selected item.
     */
    /**
    * Désélectionne l'élément actuellement sélectionné.
    */
    deselectItem() {
        this.selectedItemSubject.next(null);
    }

    /**
     * Selects a new filter item.
     * @param newFilter - The new filter element to select.
     */
    /**
    * Sélectionne un nouvel élément de filtre.
    * @param newFilter - Le nouvel élément de filtre à sélectionner.
    */
    public selectItem(newFilter: FilterOption) {
        this.selectedFilter = newFilter;
        this.selectedItemSubject.next(newFilter);

    }

    /**
     * Updates current tags with a new tag list.
     * 
     * @param tags - An array of strings representing the new tags to apply.
     */
    /**
     * Met à jour les tags actuels avec une nouvelle liste de tags.
     * 
     * @param tags - Un tableau de chaînes représentant les nouveaux tags à appliquer.
     */
    updateTags(tags: string[]): void {

    const currentTags = this.tagsSubject.getValue() || [];
    const updatedTags = [...new Set([...currentTags, ...tags])];
    this.tagsSubject.next(updatedTags);
    }
    

    /**
     * Removes all tags by resetting the tag list to an empty array.
     */
    /**
     * Supprime tous les tags en réinitialisant la liste des tags à un tableau vide.
     */
    removeTags(): void {
        this.tagsSubject.next([]);
    }

     /**
     * Removes a specific tag from the current list of tags.
     * @param tagToRemove - The tag to be removed from the list.
     */
    /**
     * Supprime un tag spécifique de la liste actuelle des tags.
     * @param tagToRemove - Le tag à supprimer de la liste.
     */
     removeTag(tagToRemove: string): void {
        const currentTags = this.tagsSubject.getValue() || [];
        const updatedTags = currentTags.filter(tag => tag !== tagToRemove);
        this.tagsSubject.next(updatedTags);
    }

    /**
     * Resets the object state by removing all tags and
     * resetting the selected element to `null`.
     */
    /**
     * Réinitialise l'état de l'objet en supprimant tous les tags et en
     * réinitialisant l'élément sélectionné à `null`.
     */
    clear(): void {
        this.tagsSubject.next([]);
        this.selectedItemSubject.next(null);
    }
}
