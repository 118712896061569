import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { DeliveryReportPage, DeliveryReportRecap, DeliveryReportShare } from 'src/app/core/models/delivery_report';
import { ScreenshotMailModel } from '../models/screenshot-mail.model';
import { CsvMail } from '../models/csv-mail';
import { AgenceId } from 'src/app/core/models/agencyId';
import { DelayReportShare, RetardResponseObject, RetardResponseRecap } from '../models/retard_response';
import { LoginModel } from '../models/login';
import { DeliveryReportHistoryResponse } from '../models/responses/delivery_report_history';
import { MapData } from '../models/mapData';
import { environment } from 'src/environments/environment';
import { DelayGraph } from '../models/delay-graph';
import { SearchModel } from '../models/search-model';
import { StatusGraph } from '../models/status-graph';
import { CsvGraph } from '../models/csv-graph';
import { KeycloakService } from './keycloak.service';


@Injectable({
  providedIn: 'root',
})
export class HttpService {

  constructor(private http: HttpClient, private keycloakService: KeycloakService) { }

  /**
   * Handles HTTP errors by distinguishing between client-side and server-side errors.
   * @param error The HttpErrorResponse object that contains information about the error.
   * @returns An Observable that emits an error object with status and message.
   */
  /**
   * Gère les erreurs HTTP en distinguant les erreurs côté client et côté serveur.
   * @param error L'objet HttpErrorResponse contenant des informations sur l'erreur.
   * @returns Un Observable qui émet un objet d'erreur avec le statut et le message.
   */
  private handleError(httpError: HttpErrorResponse) {
    let errorMessage = '';

    // Check if the error is a client-side error
    // Vérifie si l'erreur est une erreur côté client
    if (httpError.error instanceof ErrorEvent) {
      errorMessage = 'Une erreur est survenue';
    } else {
      // Server-side error 
      // Erreur côté serveur
      errorMessage = httpError?.error?.response
        ? `${httpError.error.response}`
        : `Erreur inattendue, veuillez réessayer plus tard.`;

    }

    // Construct an error object to be returned
    // Construit un objet d'erreur à retourner
    const errorResponse = {
      status: httpError.status,
      message: errorMessage
    }
    // Return an Observable that emits the error object
    // Retourne un Observable qui émet l'objet d'erreur
    return throwError(() => errorResponse);
  }

  get(url: string): Observable<any> {
    return this.http.get(url);
  }

  post(url: string, data: any): Observable<any> {
    return this.http.post(url, data);
  }

  // ----------- envoi requêtes au kpi_backend 


  sendContact(email: string, message: string): Observable<any> {
    const body = {
      email: email,
      message: message
    };
    return this.http.post<any>(`${environment.apiUrl}/users/contact`, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  login(data: any): Observable<LoginModel> {
    return this.http.post<any>(`${environment.apiUrl}/auth/login`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  ChangePassword(email: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/users/forgot`, {
      username: email
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  sendNewPassword(newpass: string, token: string): Observable<any> {
    const body = {
      token: token,
      newPassword: newpass
    };

    const result: Observable<any> = this.http.post<any>(
      `${environment.apiUrl}/users/changepassword`,
      body
    );
    return result
      .pipe(
        catchError(this.handleError)
      );
  }

  checkToken(token: string): Observable<any> {
    const url = `${environment.apiUrl}/users/checktoken`;
    const body = {
      token: token,
    };
    return this.http.post<any>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCo2(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string
  ): Observable<number> {
    const url = `${environment.apiUrl}/deliveryreport/getCO2`;
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin,
    };
    return this.http.post<any>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /** ENG
  * this function retrieves a delivery report based on certain criteria
  *
  * @param agencyId array of agency IDs to filter the report
  * @param pageNo page number to retrieve
  * @param start_date start date to filter results
  * @param end_date end date to filter results
  * @param search search term to filter results
  * @param pageSize The size of each page for pagination.
  * @param col column to sort results by
  * @param order_by sort order (ascending or descending)
  * @returns Observable<DeliveryReportPage> - An Observable that will emit an object of type DeliveryReportPage
  */
  /**
  * FR
  * Récupère le rapport de livraison en fonction des critères de recherche
  * 
  * @param agencyId Un tableau d'identifiants d'agence pour filtrer le rapport
  * @param pageNo Le numéro de page pour la pagination
  * @param date_debut La date de début pour filtrer le rapport
  * @param date_fin La date de fin pour filtrer le rapport
  * @param search La chaîne de recherche pour un filtrage supplémentaire
  * @param pageSize La taille de chaque page pour la pagination
  */
  getDeliveryReportBySearch(search_model: SearchModel): Observable<DeliveryReportPage> {
    const url = `${environment.apiUrl}/deliveryreport/getDeliveryReportBySearch`;
    const body = {
      agencies: search_model.agency_ids,
      date_debut: search_model.date_debut,
      date_fin: search_model.date_fin,
      page_number: search_model.page_no,
      search: search_model.search,
      page_size: search_model.page_size,
      order_by: search_model.order_by,
      column_filter: search_model.col_filter,
      column_order: search_model.col_order,
      sendingAll: search_model.sendingAll
    };
    return this.http.post<DeliveryReportPage>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCountDeliveryReportWithSpecificStatus(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
    status: any,
  ): Observable<number> {
    const url = `${environment.apiUrl}/deliveryreport/getCountSpecificStatus`;
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin,
      status: status,
    };
    return this.http.post<number>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /** ENG
  Retrieves the percentage change for conforming expeditions.
  @param agencyId Array of agency IDs.
  @param date_debut Start date.
  @param date_fin End date.
  @param status Status of the shipment.
  @returns Observable<number> The percentage change for conforming shipments.
  */
  /** FR
  Récupère le pourcentage de changement pour les expéditions conformes.
  @param agencyId Tableau d'identifiants d'agence.
  @param date_debut Date de début.
  @param date_fin Date de fin.
  @param status Statut de l'expédition.
  @returns Observable<number> Le pourcentage de changement pour les expéditions conformes.
  */
  getPercentageChangeByRange(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
    status: any,
  ): Observable<number> {
    const url = `${environment.apiUrl}/deliveryreport/getPercentageChangeByRange`;
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin,
      status: status,
    };
    return this.http.post<number>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCountOfAllDeliveryReportByStatut(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
    department?: string,
    operationType?: 'Expedition' | 'Delivery' | 'Quality'
  ): Observable<StatusGraph[]> {
    const url = `${environment.apiUrl}/deliveryreport/getCountOfAllDeliveryReportByStatut`;
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin,
      department: department,
      operationType: operationType
    };
    return this.http.post<StatusGraph[]>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  getHistory(num_dossier: string): Observable<DeliveryReportHistoryResponse> {
    const url = `${environment.apiUrl}/deliveryHistory/getDeliveryHistory`;
    const body = {
      num_recep: num_dossier,
    };
    return this.http.post<DeliveryReportHistoryResponse>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
  * ENG Retrieves delivery and expedition data by department for the specified agencies and time period.
  * @param agencyId Array of agency IDs.
  * @param date_debut Start date.
  * @param date_fin End date.
  * @returns An Observable of MapData array containing the delivery data.
  */
  /**
  * FR Récupère les données d'expédition et de livraison par département pour les agences spécifiées et la période de temps.
  * @param agencyId Tableau d'identifiants d'agence.
  * @param date_debut Date de début.
  * @param date_fin Date de fin.
  * @returns Un Observable d'un tableau MapData contenant les données d'expédition.
  */
  getDeliveryAndExpeditionByDepartment(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getDeliveryAndExpeditionCountByDepartment`;
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin
    };
    return this.http.post<MapData[]>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /** ENG
  * Retrieves the top receivers based on department for a given set of agencies and date range.
  * @param agencyId Array of agency IDs.
  * @param date_debut Start date of the date range.
  * @param date_fin End date of the date range.
  * @returns Observable<MapData[]> representing the top receivers by department.
  */
  /** FR
   * Récupère les principaux destinataires par département pour un ensemble donné d'agences et une plage de dates.
   * @param agencyId Tableau d'identifiants d'agence.
   * @param date_debut Date de début de la plage de dates.
   * @param date_fin Date de fin de la plage de dates.
   * @returns Observable<MapData[]> représentant les principaux destinataires par département.
   */
  getTopReceiverByDepartment(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getTopReceiverByDepartment`;
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin
    };
    return this.http.post<MapData[]>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /** ENG
   * Retrieves the top receivers based on france for a given set of agencies and date range.
   * @param agencyId Array of agency IDs.
   * @param date_debut Start date of the date range.
   * @param date_fin End date of the date range.
   * @returns Observable<MapData[]> representing the top receivers by france.
   */
  /** FR
   * Récupère les principaux destinataires de france pour un ensemble donné d'agences et une plage de dates.
   * @param agencyId Tableau d'identifiants d'agence.
   * @param date_debut Date de début de la plage de dates.
   * @param date_fin Date de fin de la plage de dates.
   * @returns Observable<MapData[]> représentant les principaux destinataires de france.
   */
  getTopReceiverByFrance(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getTopReceiverByFrance`;
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin
    };
    return this.http.post<MapData[]>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /** ENG
 * Retrieves the top senders based on department for a given set of agencies and date range.
 * @param agencyId Array of agency IDs.
 * @param date_debut Start date of the date range.
 * @param date_fin End date of the date range.
 * @returns Observable<MapData[]> representing the top senders by department.
 */
  /** FR
   * Récupère les principaux expéditeurs par département pour un ensemble donné d'agences et une plage de dates.
   * @param agencyId Tableau d'identifiants d'agence.
   * @param date_debut Date de début de la plage de dates.
   * @param date_fin Date de fin de la plage de dates.
   * @returns Observable<MapData[]> représentant les principaux expéditeurs par département.
   */
  getTopSenderByDepartment(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getTopSenderByDepartment`;
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin
    }
    return this.http.post<MapData[]>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /** ENG
* Retrieves the top senders based on france for a given set of agencies and date range.
* @param agencyId Array of agency IDs.
* @param date_debut Start date of the date range.
* @param date_fin End date of the date range.
* @returns Observable<MapData[]> representing the top senders by france.
*/
  /** FR
   * Récupère les principaux expéditeurs de france pour un ensemble donné d'agences et une plage de dates.
   * @param agencyId Tableau d'identifiants d'agence.
   * @param date_debut Date de début de la plage de dates.
   * @param date_fin Date de fin de la plage de dates.
   * @returns Observable<MapData[]> représentant les principaux expéditeurs de france.
   */
  getTopSenderByFrance(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getTopSenderByFrance`;
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin
    };
    return this.http.post<MapData[]>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /** ENG
 * Retrieves the top statuses based on department for a given set of agencies and date range.
 * @param agencyId Array of agency IDs.
 * @param date_debut Start date of the date range.
 * @param date_fin End date of the date range.
 * @returns Observable<MapData[]> representing the top statuses by department.
 */
  /** FR
   * Récupère les principaux statuts par département pour un ensemble donné d'agences et une plage de dates.
   * @param agencyId Tableau d'identifiants d'agence.
   * @param date_debut Date de début de la plage de dates.
   * @param date_fin Date de fin de la plage de dates.
   * @returns Observable<MapData[]> représentant les principaux statuts par département.
   */
  getTopStatusByDepartment(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getTopStatusByDepartment`;
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin
    };
    return this.http.post<MapData[]>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /** ENG
* Retrieves the top statuses based on france for a given set of agencies and date range.
* @param agencyId Array of agency IDs.
* @param date_debut Start date of the date range.
* @param date_fin End date of the date range.
* @returns Observable<MapData[]> representing the top statuses by france
/** FR
 * Récupère les principaux statuts de france pour un ensemble donné d'agences et une plage de dates.
 * @param agencyId Tableau d'identifiants d'agence.
 * @param date_debut Date de début de la plage de dates.
 * @param date_fin Date de fin de la plage de dates.
 * @returns Observable<MapData[]> représentant les principaux statuts de france.
 */
  getTopStatusByFrance(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getTopStatusByFrance`;
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin
    };
    return this.http.post<MapData[]>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /** ENG
  * Retrieves the count of deliveries and expeditions abroad for a given list of agencies and date range.
  * @param agencyId List of agency identifiers
  * @param date_debut Start date of the period
  * @param date_fin End date of the period
  * @returns Observable<MapData[]> An Observable containing geographical map data
  */
  /** FR
  * Récupère le nombre de livraisons et d'expéditions à l'étranger pour une liste d'agences et une plage de dates données.
  * @param agencyId Liste des identifiants d'agence
  * @param date_debut Date de début de la période
  * @param date_fin Date de fin de la période
  * @returns Observable<MapData[]> Un Observable contenant les données de la carte géographique
  */
  getCountDeliveryAndExpeditionAbroad(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getDeliveryAndExpeditionCountAbroad`;
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin,
    };
    return this.http.post<MapData[]>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /** ENG
  * Fetches retard (delay) data for a given period and list of agencies.
  * 
  * @param agencyId An array of agency IDs for which retard data is requested.
  * @param date_debut The start date of the period in string format (e.g., "YYYY-MM-DD").
  * @param date_fin The end date of the period in string format (e.g., "YYYY-MM-DD").
  * @returns An Observable emitting an array of RetardResponseObject, representing retard data.
  */
  /** FR
  * Récupère les données de retard pour une période donnée et une liste d'agences.
  * 
  * @param agencyId Un tableau d'identifiants d'agence pour lesquels les données de retard sont demandées.
  * @param date_debut La date de début de la période au format chaîne de caractères (par exemple, "AAAA-MM-JJ").
  * @param date_fin La date de fin de la période au format chaîne de caractères (par exemple, "AAAA-MM-JJ").
  * @returns Un Observable émettant un tableau de RetardResponseObject, représentant les données de retard.
  */
  getDelayReport(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string
  ): Observable<RetardResponseObject[]> {

    const url = `${environment.apiUrl}/deliveryreport/getDelayReport`;

    // Construct the request body with start and end dates, and agency IDs
    // Construit le corps de la requête avec les dates de début et de fin, et les identifiants d'agence
    const body = {
      date_debut: date_debut,
      date_fin: date_fin,
      agencies: agencyId,
    };
    return this.http.post<RetardResponseObject[]>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDelayGraph(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string
  ): Observable<DelayGraph[]> {
    const url = `${environment.apiUrl}/deliveryreport/getDelayGraph`;
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin,
    };
    return this.http.post<DelayGraph[]>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * ENG
   * Retrieves the count of delivery reports by department with a specific status for the given agencies within the specified date range.
   * 
   * @param agencyId List of agency IDs
   * @param date_debut Start date for the search (string format)
   * @param date_fin End date for the search (string format)
   * @param status Specific status to search for
   * @returns Observable<MapData[]> Observable containing the map data with the count of reports by department
   */
  /**
   * FR
   * Récupère le nombre de rapports de livraison par département avec un statut spécifique pour les agences données dans la plage de dates spécifiée.
   * 
   * @param agencyId Liste des identifiants d'agences
   * @param date_debut Date de début pour la recherche (format string)
   * @param date_fin Date de fin pour la recherche (format string)
   * @param status Statut spécifique à rechercher
   * @returns Observable<MapData[]> Observable contenant les données de la carte avec le nombre de rapports par département
   */
  getStatusCountByDepartment(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
    status: any
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getStatusCountByDepartment`;
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin,
      status: status
    };
    return this.http.post<MapData[]>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
 * ENG
 * Retrieves the count of delivery reports by France with a specific status for the given agencies within the specified date range.
 * 
 * @param agencyId List of agency IDs
 * @param date_debut Start date for the search (string format)
 * @param date_fin End date for the search (string format)
 * @param status Specific status to search for
 * @returns Observable<MapData[]> Observable containing the map data with the count of reports by France
 */
  /**
  * FR
  * Récupère le nombre de rapports de livraison en France avec un statut spécifique pour les agences données dans la plage de dates spécifiée.
  * 
  * @param agencyId Liste des identifiants d'agences
  * @param date_debut Date de début pour la recherche (format string)
  * @param date_fin Date de fin pour la recherche (format string)
  * @param status Statut spécifique à rechercher
  * @returns Observable<MapData[]> Observable contenant les données de la carte avec le nombre de rapports en France
  */
  getStatusCountByFrance(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
    status: any
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getStatusCountByFrance`;
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin,
      status: status
    };
    return this.http.post<MapData[]>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * ENG
   * Retrieves the count of delivery reports with a specific status by department
   * 
   * @param agencyId List of agency IDs
   * @param date_debut Start date for the search (string format)
   * @param date_fin End date for the search (string format)
   * @param status Specific status to search for
   * @param department Concerned department (optional)
   * @param operationType Type of operation ('Expedition', 'Delivery', or 'Quality') (optional)
   * @returns Observable<number> Observable containing the count of delivery reports
   */
  /**
   * FR
   * Récupère le nombre de rapports de livraison avec un statut spécifique par département
   * 
   * @param agencyId Liste des identifiants d'agences
   * @param date_debut Date de début pour la recherche (format string)
   * @param date_fin Date de fin pour la recherche (format string)
   * @param status Statut spécifique à rechercher
   * @param department Département concerné (optionnel)
   * @param operationType Type d'opération ('Expedition', 'Delivery' ou 'Quality') (optionnel)
   * @returns Observable<number> Observable contenant le nombre de rapports de livraison
   */
  getCountDeliveryReportWithSpecificStatusByDepartment(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
    status: any,
    department?: string,
    operationType?: 'Expedition' | 'Delivery' | 'Quality'
  ): Observable<number> {
    const url = `${environment.apiUrl}/deliveryreport/getCountSpecificStatusByDepartment`;
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin,
      status: status,
      department: department,
      operationType: operationType
    };
    return this.http.post<number>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }


  // ----------- envoi requêtes au mailer_service

  sendDeliveryReportMail(delivery_report_mail: DeliveryReportRecap) {
    const url = `${environment.mailApi}/sendDeliveryReportMail`;
    const body = {
      recipient: delivery_report_mail.recipient,
      subject: delivery_report_mail.subject,
      comments: delivery_report_mail.comments,
      dataMailDelivery: delivery_report_mail.dataMailDelivery,
      sender: delivery_report_mail.sender,
      date_debut: delivery_report_mail.date_debut,
      date_fin: delivery_report_mail.date_fin,
    };
    return this.http.post<string>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  sendDelayReportMail(delay_report_mail: RetardResponseRecap) {
    const url = `${environment.mailApi}/sendDelayReportMail`;
    const body = {
      recipient: delay_report_mail.recipient,
      subject: delay_report_mail.subject,
      comments: delay_report_mail.comments,
      dataMailDelay: delay_report_mail.dataMailDelay,
      sender: delay_report_mail.sender,
      date_debut: delay_report_mail.date_debut,
      date_fin: delay_report_mail.date_fin,
    };
    return this.http.post<string>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  sendScreenshotMail(screenshot_mail: ScreenshotMailModel): Observable<string> {
    const url = `${environment.mailApi}/sendScreenshot`;
    const body = {
      recipient: screenshot_mail.recipient,
      subject: screenshot_mail.subject,
      comments: screenshot_mail.comments,
      screenshot: screenshot_mail.screenshot,
      sender: screenshot_mail.sender,
      date_debut: screenshot_mail.date_debut,
      date_fin: screenshot_mail.date_fin,
      title: screenshot_mail.title,
      department: {
        number: screenshot_mail.department?.number,
        title: screenshot_mail.department?.title
      },
    };
    return this.http.post<string>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  sendCSVMail(csv_mail: CsvMail): Observable<string> {
    const url = `${environment.mailApi}/sendCSV`;

    const body = {
      recipient: csv_mail.recipient,
      subject: csv_mail.subject,
      comments: csv_mail.comments,
      csv: csv_mail.csv,
      sender: csv_mail.sender,
      type: csv_mail.type,
      date_debut: csv_mail.date_debut,
      date_fin: csv_mail.date_fin,
      title: csv_mail.title,
      department: {
        number: csv_mail.department?.number,
        title: csv_mail.department?.title
      },
      token: this.keycloakService.token
    };
    return this.http.post<string>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  // ----------- envoi requêtes au csv_service

  sendCSVFile(formData: FormData): Observable<any> {
    const url = `${environment.csvApi}/createDeliveryReportFromCSV`;
    return this.http.post<any>(url, formData)
      .pipe(
        catchError(this.handleError)
      );
  }

  /** FR
   * Partager le rapport de retard en générant et téléchargeant un fichier CSV.
   * @param delay_report_share Les données du rapport des retards à partager.
   * @returns Un observable indiquant le succès ou l'échec de l'opération.
   */
  /** ENG
   * Share delay report by generating and downloading CSV file.
   * @param delay_report_share The delay report data to be shared.
   * @returns An observable indicating the success or failure of the operation. 
   */
  shareDelayReport(delay_report_share: DelayReportShare) {
    const url = `${environment.csvApi}/shareDelayReportCSV`;
    const body = {
      dataShare: delay_report_share.dataShare,
      date_debut: delay_report_share.date_debut,
      date_fin: delay_report_share.date_fin,
    };
    return this.http.post(url, body, {
      responseType: 'blob'
    }).pipe(
      map(blob => {
        // On successful response, generate download link and trigger download.
        // En cas de réponse réussie, générer le lien de téléchargement et déclencher le téléchargement.
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        const fileName = `RAPPORTS_DE_RETARD_DU_${delay_report_share.date_debut}_AU_${delay_report_share.date_fin}.csv`;
        link.download = fileName;
        link.click();
        // Release the object URL to free resources.
        // Libérer l'URL de l'objet pour libérer des ressources.
        window.URL.revokeObjectURL(downloadUrl);
        // Return true to indicate successful download.
        // Renvoyer true pour indiquer le téléchargement réussi.
        return true;
      }),
    )
  }

  /** FR
   * Partager le rapport de livraison en générant et téléchargeant un fichier CSV.
   * @param delivery_report_share Les données du rapport de livraison à partager.
   * @returns Un observable indiquant le succès ou l'échec de l'opération.
   */
  /** ENG
   * Share delivery report by generating and downloading CSV file.
   * @param delivery_report_share The delivery report data to be shared.
   * @returns An observable indicating the success or failure of the operation. 
   */
  shareDeliveryReport(delivery_report_share: DeliveryReportShare) {
    const url = `${environment.csvApi}/shareDeliveryReportCSV`;
    const body = {
      dataShare: delivery_report_share.dataShare,
      date_debut: delivery_report_share.date_debut,
      date_fin: delivery_report_share.date_fin,
    };
    return this.http.post(url, body, {
      responseType: 'blob'
    }).pipe(
      catchError(this.handleError),
      map(blob => {
        // On successful response, generate download link and trigger download.
        // En cas de réponse réussie, générer le lien de téléchargement et déclencher le téléchargement.
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        const fileName = `RAPPORTS_DE_LIVRAISON_DU_${delivery_report_share.date_debut}_AU_${delivery_report_share.date_fin}.csv`;
        link.download = fileName;
        link.click();
        // Release the object URL to free resources.
        // Libérer l'URL de l'objet pour libérer des ressources.
        window.URL.revokeObjectURL(downloadUrl);
        // Return true to indicate successful download.
        // Renvoyer true pour indiquer le téléchargement réussi.
        return true;
      }),
    )
  }

  /** ENG
   * Shares a graph CSV via HTTP and returns a sharing status.
   * @param csv_graph The graph CSV data to share.
   * @returns A status indicating when the sharing operation is successfully completed.
   */
  /** FR
  * Partage un fichier CSV de graphique via HTTP et renvoie un statut de partage.
  * @param csv_graph Les données CSV du graphique à partager.
  * @returns Un statut indiquant quand l'opération de partage est terminée avec succès.
  */
  shareGraphCSV(csv_graph: CsvGraph) {

    // Construct the URL for the API endpoint
    // Construit l'URL pour le point de terminaison de l'API
    const url = `${environment.csvApi}/shareGraphCsv`;

    const body = {
      csv: csv_graph.csv,
      date_debut: csv_graph.date_debut,
      date_fin: csv_graph.date_fin,
      type: csv_graph.type,
      title: csv_graph.title
    };


    // Send a POST request to the API endpoint with the request body and headers, specifying the response type as a blob
    // Envoie une requête POST au point de terminaison de l'API avec le corps de la requête et les en-têtes,
    // en spécifiant le type de réponse comme un blob
    return this.http.post(url, body, {

      responseType: 'blob'
    }).pipe(
      catchError(this.handleError),
      map(blob => {
        // On successful response, generate download link and trigger download.
        // En cas de réponse réussie, générer le lien de téléchargement et déclencher le téléchargement.
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        // Set the href attribute of the <a> element to the download URL
        // Définit l'attribut href de l'élément <a> sur l'URL de téléchargement
        link.href = downloadUrl;
        let fileName = `${csv_graph.title}`;
        if (csv_graph.department) {
          fileName += `_Département_${csv_graph.department?.number} ${csv_graph.department?.title}`;
        } else {
          fileName += `_France_Métropolitaine`;
        }
        fileName += `_du_${csv_graph.date_debut}_au_${csv_graph.date_fin}.csv`;
        link.download = fileName;

        // Simulate a click on the <a> element to trigger the download
        // Simule un clic sur l'élément <a> pour déclencher le téléchargement
        link.click();
        // Release the object URL to free up memory
        // Libère l'URL d'objet pour libérer de la mémoire
        window.URL.revokeObjectURL(downloadUrl);
        // Return true to indicate that the sharing operation was successful
        // Retourne true pour indiquer que l'opération de partage a réussi
        return true;
      })
    )
  }
}