import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { NotificationService } from 'src/app/core/services/notification.service';
import { MailService } from 'src/app/core/services/mail.service';
import { PeriodService } from 'src/app/core/services/period.service';
import { CsvDataService } from 'src/app/core/services/csv-data.service';
import { StatusGraph } from 'src/app/core/models/status-graph';
import { StateGraph } from 'src/app/core/models/state-graph';
import { DelayGraph } from 'src/app/core/models/delay-graph';
import { CsvService } from 'src/app/core/services/csv.service';
import { KeycloakService } from 'src/app/core/services/keycloak.service';
import { MapsService } from 'src/app/core/services/maps.service';


/**
 * Variable permettant de représenter les données graphiques pour différents types de graphiques.
 * Peut être un tableau de StatusGraph, StateGraph ou DelayGraph.
 */
type GraphData = StatusGraph[] | StateGraph[] | DelayGraph[];

@Component({
  selector: 'app-export-data-graph',
  templateUrl: './export-data-graph.component.html',
  styleUrls: ['./export-data-graph.component.css'],
})
export class ExportDataGraphComponent implements OnInit {
  mailsend = false;
  clicksend = false;
  previewgraph = false;
  previewexcel = false;
  sendForm: FormGroup;
  graph_title: string;
  graph_screenshot: string;
  required = false;
  invalid_mail = false;
  invalid_choice = false;
  object_default = '';
  showMailForm = false;

  constructor(
    private mailService: MailService,
    private notificationService: NotificationService,
    private csvDataService: CsvDataService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ExportDataGraphComponent>,
    private periodService: PeriodService,
    private keycloakService: KeycloakService,
    private csvService: CsvService,
    private mapService: MapsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.graph_screenshot = data.graph_screenshot;
    this.graph_title = data.graph_title;
    this.sendForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.minLength(3)]],
      comments: [''],
    });
  }

  ngOnInit(): void {
    // on récupère le département depuis le service
    // we retrieve the department from the service
    const department = this.mapService.getSelectedDepartment();

    // on définit l'objet par défault du mail 
    // we define the default subject of the email
    this.object_default =
      'Rapport de: `' +
      this.graph_title
    if (department) {
      this.object_default += ' Département ' + department.number + ' ' + department?.title
    } else {
      this.object_default += ' France Métropolitaine '
    }
    this.object_default +=
      '` du ' +
      this.periodService.getDateDebutAffichageFR() +
      ' au ' +
      this.periodService.getDateFinAffichageFR();
  }

  onSubmit() {
    const emailControl = this.sendForm.get('email');
    if (!emailControl) {
      console.error("La commande 'email' n'est pas disponible dans le formulaire.");
      return;
    }

    this.required = emailControl.hasError('required');
    this.invalid_mail = emailControl.hasError('email');


    if (this.sendForm.valid && !this.clicksend) {
      this.clicksend = true;
      this.sendMail();
    }
  }

  /**
   * FR: Fonction qui construit un @ScreenshotMailModel ou/et @CsvMail avec les datas du formulaire @sendForm et du @csvDataService
   * EN: Function that constructs a @ScreenshotMailModel and/or a @CsvMail model with the data from the @sendForm form and the @csvDataService service.
   */
  sendMail() {
    const subjectValue = this.sendForm.get('subject')?.value;
    this.sendForm
      .get('subject')
      ?.setValue(
        subjectValue === ''
          ? this.object_default : subjectValue
      );

    if (this.previewgraph) {
      if (!this.keycloakService.profile) return;
      this.mailService.sendScreenshotMail({
        recipient: this.sendForm.get('email')?.value,
        subject: this.sendForm.get('subject')?.value,
        comments: this.sendForm.get('comments')?.value,
        screenshot: this.graph_screenshot,
        sender: this.keycloakService.profile!.preferred_username,
        date_debut: this.periodService.getDateDebutwithFormat("dd-MM-yyyy"),
        date_fin: this.periodService.getDateFinwithFormat("dd-MM-yyyy"),
        title: this.graph_title,
        department: this.mapService.getSelectedDepartment()
      })
        .subscribe({
          next: () => {
            this.notificationService.notifySuccess("Email de partage envoyé");
          },
          error: (erreur) => {
            this.notificationService.notifyError(erreur.message);
            this.closeModal();
          },
          complete: () => {
            this.closeModal();
          }
        });
    }

    if (this.previewexcel) {

      let csvData: GraphData;

      switch (this.graph_title) {
        case 'Retards':
          csvData = this.csvDataService.getDelayGlobal();
          this.callSendCSV(csvData, 'DelayGraph');
          break;
        case 'Rapports de livraison par statut':
          csvData = this.csvDataService.getDeliveryReportByStatusGlobal();
          this.callSendCSV(csvData, 'StatusGraph');
          break;
        case 'Etat des rapports de livraison':
          csvData = this.csvDataService.getStateOfDeliveryReportsGlobal();
          this.callSendCSV(csvData, 'StateGraph');
          break;
        default:
          break;
      }
    }
  }
  /**
   * FR: Fonction d'envoie du mail spécifique au csv
   * EN: Function for sending the email specific to the CSV file.
   * @param csvData
   * @param type
   * FR: Chaine de caractère qui définit quelle type de datas est envoyé
   * EN: String that defines the type of data being sent.
   */
  callSendCSV(
    csvData: GraphData,
    type: string
  ) {
    forkJoin([
      this.mailService.sendCSVMail({
        recipient: this.sendForm.get('email')?.value,
        subject: this.sendForm.get('subject')?.value,
        comments: this.sendForm.get('comments')?.value,
        csv: csvData,
        type: type,
        sender: this.keycloakService.profile!.preferred_username,
        date_debut: this.periodService.getDateDebutwithFormat('dd-MM-yyyy'),
        date_fin: this.periodService.getDateFinwithFormat('dd-MM-yyyy'),
        title: this.graph_title,
        department: this.mapService.getSelectedDepartment()
      }),
    ]).subscribe({
      next: () => {
        this.notificationService.notifySuccess("Email de partage envoyé");
      },
      error: (erreur) => {
        this.notificationService.notifyError(erreur.message);
        this.closeModal();
      },
      complete: () => {
        this.closeModal();
      }
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  choiceexcel() {
    this.previewexcel = !this.previewexcel;
  }

  choicegraph() {
    this.previewgraph = !this.previewgraph;
  }


  toggleMailForm() {
    // Afficher le formulaire de mail seulement si previewgraph, previewexcel ou les 2 est vrai
    if (this.previewgraph || this.previewexcel) {
      this.showMailForm = !this.showMailForm;
      this.invalid_choice = false;
    } else {
      this.invalid_choice = true;
    }
  }


  /** ENG
  * Calls the service to share a CSV file, then handles the results.
  * 
  * @param csvData The CSV data to share, as an array of StatusGraph, StateGraph, or DelayGraph.
  * @param type The type of CSV data to share.
  */
  /** FR
  * Appelle le service pour partager un fichier CSV, puis gère les résultats.
  * 
  * @param csvData Les données CSV à partager, sous forme de tableau de StatusGraph, StateGraph ou DelayGraph.
  * @param type Le type de données CSV à partager.
  */
  callShareCSV(
    csvData: GraphData,
    type: string
  ) {

    // Calls the service to share the CSV file using the CSV data, the specified type,
    // the start date, end date, and the graph title.
    // Appelle le service pour partager le fichier CSV en utilisant les données CSV, le type spécifié,
    // la date de début, la date de fin et le titre du graphique.
    forkJoin([
      this.csvService.shareGraphCSV({
        csv: csvData,
        type: type,
        date_debut: this.periodService.getDateDebutwithFormat('dd-MM-yyyy'),
        date_fin: this.periodService.getDateFinwithFormat('dd-MM-yyyy'),
        title: this.graph_title,
        department: this.mapService.getSelectedDepartment()
      }),
    ]).subscribe({

      next: () => {
        this.notificationService.notifySuccess("Téléchargement du graphique effectué.");
      },
      // On error, displays an error notification and closes the modal.
      // En cas d'erreur, affiche une notification d'erreur et ferme le modal.
      error: (erreur) => {
        this.notificationService.notifyError(erreur.message);
        this.closeModal();
      },

      // At the end of the operation, regardless of success or failure, closes the modal.
      // À la fin de l'opération (qu'elle ait réussi ou échoué), ferme le modal.
      complete: () => {
        this.closeModal();
      }
    });
  }

  /** ENG
  * Downloads either a screenshot or a CSV file based on the preview mode.
  * If previewing a graph and not an Excel file, downloads the graph screenshot.
  * If previewing an Excel file and not a graph, shares the corresponding CSV data.
  * If both graph and Excel are being previewed, downloads the graph screenshot and shares the CSV data.
  */
  /** FR
  * Télécharge soit une capture d'écran, soit un fichier CSV en fonction du mode d'aperçu.
  * Si un graphique est visualisé et non un fichier Excel, télécharge la capture d'écran du graphique.
  * Si un fichier Excel est visualisé et non un graphique, partage les données CSV correspondantes.
  * Si à la fois un graphique et un fichier Excel sont visualisés, télécharge la capture d'écran du graphique et partage les données CSV.
  */
  downloadScreenshot() {

    const graphState = `${this.previewgraph}_${this.previewexcel}`;

    switch (graphState) {
      case 'true_false':
        this.downloadGraphScreenshot();
        break;
      case 'false_true':
        this.downloadCSVFile();
        break;
      case 'false_false':
        this.invalid_choice = true;
        break;
      case 'true_true':
        this.downloadGraphScreenshot();
        this.downloadCSVFile();
        break;
      default:
        break;
    }
  }

  /** FR
  * Télécharge la capture d'écran du graphique.
  */
  /** ENG
   * Downloads the graph screenshot.
   */
  private downloadGraphScreenshot(): void {
    // Récupération du département dans le service
    // Recovery of the department in the service
    const department = this.mapService.getSelectedDepartment();

    // Création du lien de téléchargement
    // Creation of the download link
    const downloadLinkGraph = document.createElement('a');
    downloadLinkGraph.href = this.graph_screenshot;

    // Définition du nom du fichier
    // Setting the file name
    downloadLinkGraph.download = 'Graphique_' + this.graph_title;
    if (department) {
      downloadLinkGraph.download += '_Département_' + department.number + ' ' + department.title;
    } else {
      downloadLinkGraph.download += '_France_Métropolitaine';
    }
    downloadLinkGraph.download += '_du_' + this.periodService.getDateDebutwithFormat('dd-MM-yyyy') + '_au_' + this.periodService.getDateFinwithFormat('dd-MM-yyyy');
    downloadLinkGraph.click();

    this.closeModal();
    this.notificationService.notifySuccess("Téléchargement du graphique effectué.");
  }

  /** FR
  * Télécharge le fichier CSV du graphique.
  */
  /** ENG
   * Download the CSV file of the graph.
   */
  private downloadCSVFile(): void {
   
    let csvData: GraphData;

    switch (this.graph_title) {
      case 'Retards':
        csvData = this.csvDataService.getDelayGlobal();
        this.callShareCSV(csvData, 'DelayGraph');
        break;
      case 'Rapports de livraison par statut':
        csvData = this.csvDataService.getDeliveryReportByStatusGlobal();
        this.callShareCSV(csvData, 'StatusGraph');
        break;
      case 'Etat des rapports de livraison':
        csvData = this.csvDataService.getStateOfDeliveryReportsGlobal();
        this.callShareCSV(csvData, 'StateGraph');
        break;
      default:
        break;
    }
  }

}
