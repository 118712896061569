import { Injectable } from "@angular/core";
import { DropdownAgencyService } from "./dropdown-agency.service";
import { AgencyService } from "./agency.service";
import { TabService } from "./tab.service";

@Injectable({
    providedIn: 'root'
})
export class InitializationService {


    constructor(
        private dropdownAgencyService: DropdownAgencyService,
        private agencyService: AgencyService,
        private tabService: TabService
    ){}

    initServices(): void{
        this.tabService.resetAll();
        this.dropdownAgencyService.init(this.agencyService.agencies);
        this.tabService.init();
    }
}