import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http-client.service';
import { PeriodService } from 'src/app/core/services/period.service';
import { MapData } from 'src/app/core/models/mapData';
import { BehaviorSubject, Observable, catchError, forkJoin, map, of } from 'rxjs';
import { SearchFilters } from '../configs/search-filters';
import { DropdownAgencyService } from './dropdown-agency.service';
import { Department } from '../models/department';
import { NotificationService } from './notification.service';
import { LoaderService } from './loader.service';
import { KeycloakService } from './keycloak.service';

@Injectable({
  providedIn: 'root'
})
export class MapsService {

  private listmapSubject = new BehaviorSubject<MapData[]>([]);
  listmap$: Observable<MapData[]> = this.listmapSubject.asObservable();

  allReports: MapData[] = [];
  conformingReports: MapData[] = [];

  // BehaviorSubject qui contient la sélection actuelle du département ou null
  // BehaviorSubject that contains the current department selection or null
  private selectedDepartmentSubject: BehaviorSubject<Department | null> = new BehaviorSubject<Department | null>(null);

  // Observable exposé pour permettre aux composants de s'abonner aux changements de département
  // Observable exposed to allow components to subscribe to department changes
  selectedDepartment$: Observable<Department | null> = this.selectedDepartmentSubject.asObservable();

  constructor(private httpService: HttpService,
    private periodService: PeriodService,
    private dropdownAgencyService: DropdownAgencyService,
    private keycloakService: KeycloakService,
    private notificationService: NotificationService,
    private loaderService: LoaderService
  ) { 
    this.keycloakService.logout$.subscribe(
      () => {
        this.resetData();
      }
    );
  }


  /**
   * Resets the data to its default state.
   */
  /**
   * Réinitialise les données à leur état par défaut.
   */
  resetData(): void {
    this.allReports = [];
    this.conformingReports = [];
    this.listmapSubject.next([]);
    this.periodService.setDateDebut(new Date());
    this.periodService.setDateFin(new Date());
  }

  /**
   * Définit le département sélectionné actuel
   * @param department représente le département 
   */
  /**
  * Sets the current selected department
  * @param department represent department
  */
  setSelectedDepartment(department: Department | null): void {
    this.selectedDepartmentSubject.next(department);
  }

  /**
   * Retourne le département actuellement sélectionné (ou null si aucun département n'est sélectionné)
   * @returns le département
   */
  /**
  * Returns the currently selected department (or null if no department is selected)
  * @returns the department
  */
  getSelectedDepartment(): Department | null {
    return this.selectedDepartmentSubject.value;
  }

  /**
   * Supprime la sélection actuelle du département (définit à null)
   */
  /**
  * Removes current department selection (set to null)
  */
  removeSelectedDepartment(): void {
    this.selectedDepartmentSubject.next(null);
  }

  /**
 * ENG Updates the colors of departments on the map based on the specified data and total.
 * @param data Array of map data.
 * @param total Total value used for calculating percentages.
 */
  /**
   * FR Met à jour les couleurs des départements sur la carte en fonction des données et du total spécifiés.
   * @param data Tableau de données de la carte.
   * @param total Valeur totale utilisée pour calculer les pourcentages.
   */

  public updateColors(data: MapData[]): void {
    for (let i = 1; i <= 95; i++) {
      const departmentId = i < 10 ? '0' + i : i.toString();
      const departmentExists = data.some(item => item.department === departmentId);
      if (!departmentExists) {
        const pathElements = document.getElementsByClassName(departmentId);
        Array.from(pathElements).forEach((pathElement: Element) => {
          pathElement.setAttribute('fill', '#D3D3D3'); // Gris
        });
      }
    }

    data.forEach(item => {
      if (item.department && !isNaN(parseInt(item.department))) {
        const departmentNumber = parseInt(item.department);
        if (departmentNumber >= 1 && departmentNumber <= 95 && departmentNumber !== 20) {
          const pathElement = document.getElementsByClassName(item.department.toString());
          if (pathElement) {
            let color = '';

            if (item.percentage >= 0) {
              color = this.colorsExpeditionDelivery(item.percentage);
            } else if (item.percentage_conformity >= 0) {
              color = this.colorsQuality(item.percentage_conformity, item.percentage_not_conformity);
            }

            Array.from(pathElement).forEach((pathElement: Element) => {
              pathElement.setAttribute('fill', color);
            });
          }
        }
      }
    });
  }

  /**
   * Fetches all necessary data for the map component.
   * 
   * This method uses `forkJoin` to run multiple API calls in parallel and combine their results.
   * It fetches the following data:
   * - List of map data for expeditions and deliveries
   * - Most frequent receivers by department and by France
   * - Most frequent senders by department and by France
   * - Most frequent status by department and by France
   * - Count of expeditions and deliveries abroad
   * - Rate data
   * 
   * @returns {Observable<any>} An observable that emits the combined results of all the API calls.
   */

  /**
   * Récupère toutes les données nécessaires pour le composant de la carte.
   * 
   * Cette méthode utilise `forkJoin` pour exécuter plusieurs appels API en parallèle et combiner leurs résultats.
   * Elle récupère les données suivantes :
   * - Liste des données de la carte pour les expéditions et les livraisons
   * - Les destinataires les plus fréquents par département et en France
   * - Les expéditeurs les plus fréquents par département et en France
   * - Les statuts les plus fréquents par département et en France
   * - Le nombre d'expéditions et de livraisons à l'étranger
   * - Le taux de conformité
   * 
   * @returns {Observable<any>} Un observable qui émet les résultats combinés de tous les appels API.
   */
  fetchAllData(): Observable<any> {
    return forkJoin({
      listMapData: this.fetchExpeditionAndDeliveryData(),
      receiverDataByDepartment: this.fetchMostFrequentReceiverByDepartment(),
      receiverDataByFrance: this.fetchMostFrequentReceiverByFrance(),
      senderDataByDepartment: this.fetchMostFrequentSenderByDepartment(),
      senderDataByFrance: this.fetchMostFrequentSenderByFrance(),
      statusDataByDepartment: this.fetchMostFrequentStatusByDepartment(),
      statusDataByFrance: this.fetchMostFrequentStatusByFrance(),
      countExpeditionAndDeliveryAbroad: this.fetchCountExpeditionAndDeliveryAbroadData(),
      rateData: this.fetchRateQuality()
    });
  }


  //------------------------------
  // Calculer le nombre de livraisons et expéditions 
  // To calculate the number of deliveries and expeditions

  /**
  * ENG Fetches expedition and delivery data for departments.
  * @returns An Observable of a MapData array containing expedition data.
*/
  /**
    * FR Récupère les données d'expédition et livraison pour les départements.
    * @returns Un Observable d'un tableau MapData contenant les données d'expédition.
  */
  fetchExpeditionAndDeliveryData(): Observable<MapData[]> {
    return this.httpService.getDeliveryAndExpeditionByDepartment(
      this.dropdownAgencyService.selectedAgencies,
      this.periodService.getDateDebut(),
      this.periodService.getDateFin())
      .pipe(
        map((datas: MapData[]) => {
          this.listmapSubject.next(datas);
          return datas;
        }),
        catchError((error) => {
          this.notificationService.notifyError(error.message);
          this.loaderService.endLoading();
          return of([]);
        })
      );
  }
  /** ENG
  * Retrieves the count of expeditions and deliveries abroad from the backend service.
  * @returns Observable<{ expeditionTotal: number, deliveryTotal: number }> An Observable containing the total count of expeditions and deliveries abroad
  */
  /** FR
  * Récupère le nombre d'expéditions et de livraisons à l'étranger à partir du service backend.
  * @returns Observable<{ expeditionTotal: number, deliveryTotal: number }> Un Observable contenant le nombre total d'expéditions et de livraisons à l'étranger
  */
  fetchCountExpeditionAndDeliveryAbroadData(): Observable<{ expeditionTotal: number, deliveryTotal: number }> {

    // Call the backend service to fetch the count of expeditions and deliveries abroad
    // Appelle le service backend pour récupérer le nombre d'expéditions et de livraisons à l'étranger
    return this.httpService.getCountDeliveryAndExpeditionAbroad(
      this.dropdownAgencyService.selectedAgencies,
      this.periodService.getDateDebut(),
      this.periodService.getDateFin()
    ).pipe(

      // Map the response data to calculate the total count of expeditions and deliveries
      // Mappe les données de la réponse pour calculer le nombre total d'expéditions et de livraisons
      map((datas: { operationType: string, operationCount: number }[]) => {

        // Find the count of expeditions and deliveries from the response data
        // Trouve le nombre d'expéditions et de livraisons dans les données de la réponse
        const expeditionTotal = datas.find(data => data.operationType === 'Expedition')?.operationCount ?? 0;
        const deliveryTotal = datas.find(data => data.operationType === 'Delivery')?.operationCount ?? 0;

        return { expeditionTotal, deliveryTotal };
      }),
      catchError((error) => {
        this.notificationService.notifyError(error.message);
        this.loaderService.endLoading();
        return of({ expeditionTotal: 0, deliveryTotal: 0 });
      })
    );
  }

  /**
  * ENG Calculates the rate of “delivery” or expedition activity by department.
  * @param data Array of MapData.
  * @param operationType type "delivery" or "expedition"
  * @returns The total value calculated.
*/
  /**
    * FR Calcule le taux d'activité en "livraison" ou en "expedition" par département.
    * @param data Tableau de MapData.
    * @param operationType le type "livraison" ou "expédition"
    * @returns La valeur totale calculée.
  */
  public calculateTotalAndPercentage(data: MapData[], operationType: string): number {
    let total = 0;

    // calculate the total operation count
    // calculer le nombre total d'opérations
    data.forEach(item => {
      if (item.department && item.operationType === operationType && !isNaN(parseInt(item.department))) {
        total += item.operationCount;
      }
    });

    // calculate the percentage for each item based on the total.
    // calculer le pourcentage pour chaque élément en fonction du total
    data.forEach(item => {
      if (item.department && item.operationType === operationType && !isNaN(parseInt(item.department))) {
        item.percentage = ((item.operationCount / total) * 100);
      }
    });
    return total;
  }


  /**
 * Determines the color based on the percentage of expeditions or deliveries.
 * 
 * This method returns a color code string depending on the given percentage value.
 * It categorizes the percentage into different ranges and assigns a specific color
 * to each range.
 * 
 * @param {number} percentage - The percentage value to determine the color for.
 * @returns {string} The color code corresponding to the given percentage.
 */

  /**
   * Détermine la couleur en fonction du pourcentage d'expéditions ou de livraisons.
   * 
   * Cette méthode retourne une chaîne de code de couleur en fonction de la valeur du pourcentage donnée.
   * Elle catégorise le pourcentage en différentes plages et assigne une couleur spécifique
   * à chaque plage.
   * 
   * @param {number} percentage - La valeur du pourcentage pour déterminer la couleur.
   * @returns {string} Le code couleur correspondant au pourcentage donné.
   */
  private colorsExpeditionDelivery(percentage: number): string {
    switch (true) {
      case (percentage === 0):
        return '#D3D3D3';
      case (percentage > 0 && percentage <= 2.5):
        return 'orange';
      case (percentage > 2.5 && percentage <= 5):
        return 'yellow';
      case (percentage > 5 && percentage <= 7.5):
        return 'darkgreen';
      case (percentage > 7.5):
        return 'lightgreen';
      default:
        return '#D3D3D3';
    }
  }

  //------------------------------
  // Récupérer le top 3 des destinataires 
  // Retrieve the top 3 receivers

  /** ENG
   * Fetches the most frequent receiver by department.
   * @returns An Observable of MapData array containing the fetched data.
  */
  /** FR
   * Récupère les destinataires les plus fréquentes par département.
   * @returns Un Observable d'un tableau MapData contenant les données récupérées.
  */

  fetchMostFrequentReceiverByDepartment(): Observable<MapData[]> {
    return this.httpService.getTopReceiverByDepartment(
      this.dropdownAgencyService.selectedAgencies,
      this.periodService.getDateDebut(),
      this.periodService.getDateFin()
    ).pipe(
      catchError((error) => {
        this.notificationService.notifyError(error.message);
        this.loaderService.endLoading();
        return of([]);
      })
    );
  }

  /** ENG
 * Fetches the most frequent receiver by france.
 * @returns An Observable of MapData array containing the fetched data.
*/
  /** FR
   * Récupère les destinataires les plus fréquentes de france.
   * @returns Un Observable d'un tableau MapData contenant les données récupérées.
  */
  fetchMostFrequentReceiverByFrance(): Observable<MapData[]> {
    return this.httpService.getTopReceiverByFrance(
      this.dropdownAgencyService.selectedAgencies,
      this.periodService.getDateDebut(),
      this.periodService.getDateFin()).pipe(
        catchError((error) => {
          this.notificationService.notifyError(error.message);
          this.loaderService.endLoading();
          return of([]);
        })
      );
  }
  //------------------------------
  // Récupérer le top 3 des expéditeurs 
  // Retrieve the top 3 senders

  /** ENG
   * Fetches the most frequent sender by department.
   * @returns An Observable of MapData array containing the fetched data.
  */
  /** FR
   * Récupère les expéditeurs les plus fréquent par département.
   * @returns Un Observable d'un tableau MapData contenant les données récupérées.
  */
  fetchMostFrequentSenderByDepartment(): Observable<MapData[]> {
    return this.httpService.getTopSenderByDepartment(
      this.dropdownAgencyService.selectedAgencies,
      this.periodService.getDateDebut(),
      this.periodService.getDateFin()).pipe(
        catchError((error) => {
          this.notificationService.notifyError(error.message);
          this.loaderService.endLoading();
          return of([]);
        })
      );
  }

  /** ENG
   * Fetches the most frequent sender by france.
   * @returns An Observable of MapData array containing the fetched data.
  */
  /** FR
   * Récupère les expéditeurs les plus fréquent de france.
   * @returns Un Observable d'un tableau MapData contenant les données récupérées.
  */
  fetchMostFrequentSenderByFrance(): Observable<MapData[]> {
    return this.httpService.getTopSenderByFrance(
      this.dropdownAgencyService.selectedAgencies,
      this.periodService.getDateDebut(),
      this.periodService.getDateFin()).pipe(
        catchError((error) => {
          this.notificationService.notifyError(error.message);
          this.loaderService.endLoading();
          return of([]);
        })
      );
  }

  //------------------------------
  // Récupérer le top 3 des statuts en expédition ou livraison 
  // Retrieve the top 3 expedition or delivery statuses

  /** ENG
  * Fetches the most frequent status by department.
  * @returns An Observable of MapData array containing the fetched data.
 */
  /** FR
   * Récupère les statuts les plus fréquent par département.
   * @returns Un Observable d'un tableau MapData contenant les données récupérées.
  */
  fetchMostFrequentStatusByDepartment(): Observable<MapData[]> {
    return this.httpService.getTopStatusByDepartment(
      this.dropdownAgencyService.selectedAgencies,
      this.periodService.getDateDebut(),
      this.periodService.getDateFin()
    ).pipe(
      catchError((error) => {
        this.notificationService.notifyError(error.message);
        this.loaderService.endLoading();
        return of([]);
      })
    );
  }

  /** ENG
  * Fetches the most frequent status by france.
  * @returns An Observable of MapData array containing the fetched data.
 */
  /** FR
   * Récupère les statuts les plus fréquent de france.
   * @returns Un Observable d'un tableau MapData contenant les données récupérées.
  */
  fetchMostFrequentStatusByFrance(): Observable<MapData[]> {
    return this.httpService.getTopStatusByFrance(
      this.dropdownAgencyService.selectedAgencies,
      this.periodService.getDateDebut(),
      this.periodService.getDateFin()).pipe(
        catchError((error) => {
          this.notificationService.notifyError(error.message);
          this.loaderService.endLoading();
          return of([]);
        })
      );
  }

  //------------------------------
  // Calculer la qualité de livraison
  // To calculate delivery quality

  /** ENG
  *  
  * Retrieves and calculates the rates of conformity, reports without status, non-conformity, and delivering reports
  * for both departments and France.
  * 
  * @returns An Observable containing the percentages of conformity, non-conformity, reports without status,
  *   and delivering reports by department as well as for France.
  */
  /** FR
  * 
  * Récupère et calcule les taux de conformité, les rapports sans statut, la non-conformité et les rapports 
  * en cours de livraison tant pour les départements que pour la France.
  * 
  * @returns Un Observable contenant :Les % pourcentage de conformité, non conformité, sans statut 
  *   et en cours de livraison par département et pour la france.
  */
  fetchRateQuality(): Observable<{
    conformityData: MapData[];
    withoutStatusData: MapData[];
    notConformingData: MapData[];
    deliveringData: MapData[];
    conformityDataFrance: number;
    withoutStatusDataFrance: number;
    notConformingDataFrance: number;
    deliveringDataFrance: number;
  }> {
    const selectedAgencies = this.dropdownAgencyService.selectedAgencies;
    const dateDebut = this.periodService.getDateDebut();
    const dateFin = this.periodService.getDateFin();

    return forkJoin([
      this.httpService.getStatusCountByDepartment(selectedAgencies, dateDebut, dateFin, SearchFilters.TOTAL),
      this.httpService.getStatusCountByDepartment(selectedAgencies, dateDebut, dateFin, SearchFilters.TOTAL_HORS_SS_MEL),
      this.httpService.getStatusCountByDepartment(selectedAgencies, dateDebut, dateFin, SearchFilters.CONFORME),
      this.httpService.getStatusCountByDepartment(selectedAgencies, dateDebut, dateFin, SearchFilters.SANS_STATUT),
      this.httpService.getStatusCountByDepartment(selectedAgencies, dateDebut, dateFin, SearchFilters.NON_CONFORME),
      this.httpService.getStatusCountByDepartment(selectedAgencies, dateDebut, dateFin, SearchFilters.MISE_EN_LIVRAISON),
      this.httpService.getStatusCountByFrance(selectedAgencies, dateDebut, dateFin, SearchFilters.TOTAL),
      this.httpService.getStatusCountByFrance(selectedAgencies, dateDebut, dateFin, SearchFilters.TOTAL_HORS_SS_MEL),
      this.httpService.getStatusCountByFrance(selectedAgencies, dateDebut, dateFin, SearchFilters.CONFORME),
      this.httpService.getStatusCountByFrance(selectedAgencies, dateDebut, dateFin, SearchFilters.SANS_STATUT),
      this.httpService.getStatusCountByFrance(selectedAgencies, dateDebut, dateFin, SearchFilters.NON_CONFORME),
      this.httpService.getStatusCountByFrance(selectedAgencies, dateDebut, dateFin, SearchFilters.MISE_EN_LIVRAISON),
    ]).pipe(
      map(([
        allReports,
        allReportsOutWithoutStatutsAndDelivery,
        conformingReports,
        withoutStatusReports,
        notConformingReports,
        deliveringReports,
        allReportsFrance,
        allReportsOutWithoutStatutsAndDeliveryFrance,
        conformingReportsFrance,
        withoutStatusReportsFrance,
        notConformingReportsFrance,
        deliveringReportsFrance
      ]) => {

        // Calculate conformity rate
        const conformityData = this.calculateRateQuality(allReportsOutWithoutStatutsAndDelivery, conformingReports, 'percentage_conformity');

        // Calculate conformity rate for reports without status
        const withoutStatusData = this.calculateRateQuality(allReports, withoutStatusReports, 'percentage_without_status');

        // Calculate conformity rate for non-conforming reports
        const notConformingData = this.calculateRateQuality(allReportsOutWithoutStatutsAndDelivery, notConformingReports, 'percentage_not_conformity');

        // Calculate shipments being delivered rate for delivery reports 
        const deliveringData = this.calculateRateQuality(allReports, deliveringReports, 'percentage_delivering');

        // Calculate global percentages for France
        const conformityDataFrance = this.calculateRateQualityFrance(allReportsOutWithoutStatutsAndDeliveryFrance, conformingReportsFrance);
        const withoutStatusDataFrance = this.calculateRateQualityFrance(allReportsFrance, withoutStatusReportsFrance);
        const notConformingDataFrance = this.calculateRateQualityFrance(allReportsOutWithoutStatutsAndDeliveryFrance, notConformingReportsFrance);
        const deliveringDataFrance = this.calculateRateQualityFrance(allReportsFrance, deliveringReportsFrance);

        return {
          conformityData,
          withoutStatusData,
          notConformingData,
          deliveringData,
          conformityDataFrance,
          withoutStatusDataFrance,
          notConformingDataFrance,
          deliveringDataFrance
        };
      }),
      catchError((error) => {
        this.notificationService.notifyError(error.message);
        this.loaderService.endLoading();

        return of({
          conformityData: [],
          withoutStatusData: [],
          notConformingData: [],
          deliveringData: [],
          conformityDataFrance: 0,
          withoutStatusDataFrance: 0,
          notConformingDataFrance: 0,
          deliveringDataFrance: 0
        });
      })
    );
  }

  /** ENG
 * Calculates the rate of quality for each department based on the specified reports.
 * @param allReports An array of MapData representing total reports for each department.
 * @param selectedReports An array of MapData representing the selected type of reports for each department.
 * @param rateProperty The property name to store the calculated rate in each MapData object.
 * @returns An array of MapData representing the calculated rate data for each department.
 */
  /** FR
   * Calcule le taux de qualité pour chaque département en fonction des rapports spécifiés.
   * @param allReports Un tableau de MapData représentant le total des rapports pour chaque département.
   * @param selectedReports Un tableau de MapData représentant le type de rapports sélectionné pour chaque département.
   * @param rateProperty Le nom de la propriété pour stocker le taux calculé dans chaque objet MapData.
   * @returns Un tableau de MapData représentant les données de taux calculées pour chaque département.
   */
  private calculateRateQuality(allReports: MapData[], selectedReports: MapData[], rateProperty: string): MapData[] {

    // Create a Map to store the total number of reports per department
    // Crée une Map pour stocker le nombre total de rapports par département
    const totalReportsByDepartment = new Map<string, number>();

    allReports.forEach(report => totalReportsByDepartment.set(report.department, report.statusCount));

    // Create a Map to store the number of the specified report type per department
    // Crée une Map pour stocker le nombre du type de rapports spécifiés par département
    const selectedReportsByDepartment = new Map<string, number>();
    selectedReports.forEach(report => selectedReportsByDepartment.set(report.department, report.statusCount || 0));


    // Run through allReports array to calculate rate for each department
    // Parcourt le tableau allReports pour calculer le taux pour chaque département
    allReports.forEach(report => {
      const totalReports = totalReportsByDepartment.get(report.department) ?? 0;
      const selectedReportsCount = selectedReportsByDepartment.get(report.department) ?? 0;

      // Add condition to calculate percentage only if totalReports is not zero
      // Ajoute une condition pour calculer le pourcentage uniquement si totalReports n'est pas zéro
      if (totalReports !== 0) {
        const rate = (selectedReportsCount / totalReports) * 100;
        report[rateProperty] = parseFloat(rate.toFixed(2));
      } else {
        report[rateProperty] = 0;
      }
    });

    return allReports;
  }

 /** ENG
 * Calculates the rate of selected reports as a percentage of all reports for France.
 * 
 * @param allReports List of all reports with their status counts.
 * @param selectedReports List of selected reports with their status counts.
 * @returns The percentage of selected reports relative to all reports.
 */
  /** FR
  * Calcule le taux de rapports sélectionnés en pourcentage par rapport à tous les rapports pour la France.
  * 
  * @param allReports Liste de tous les rapports avec leurs décomptes de statut.
  * @param selectedReports Liste des rapports sélectionnés avec leurs décomptes de statut.
  * @returns Le pourcentage de rapports sélectionnés par rapport à tous les rapports.
  */
  private calculateRateQualityFrance(allReports: MapData[], selectedReports: MapData[]): number {
    // Calculate the total `statusCount` for all reports
    // Calculer le total des `statutCount` pour tous les rapports
    const totalReportsCount = allReports.reduce((sum, report) => sum + (report.statusCount ?? 0), 0);

    // Calculate the total `statusCount` for selected reports
    // Calculer le total des `statutCount` pour les rapports sélectionnés
    const totalSelectedReportsCount = selectedReports.reduce((sum, report) => sum + (report.statusCount ?? 0), 0);

    // Calculate the overall percentage
    // Calculer le pourcentage global
    const globalPercentage = totalReportsCount !== 0 ? (totalSelectedReportsCount / totalReportsCount) * 100 : 0;

    // Return the percentage
    // Retourner le pourcentage
    return parseFloat(globalPercentage.toFixed(2));
  }



  /** ENG
  * Determines the color based on the percentage of conformity and not conformity.
  * 
  * This method returns a color code string depending on the given percentage value.
  * It categorizes the percentage into different ranges and assigns a specific color
  * to each range.
  * 
  * @param {number} percentage_c - conformity percentage value.
  * @param {number} percentage_not_c - not conformity percentage value.
  * @returns {string} The color code corresponding to the given percentage.
  */

  /** FR
   * Détermine la couleur en fonction du pourcentage de conformité et non conformité.

   * Cette méthode retourne une chaîne de code de couleur en fonction de la valeur du pourcentage donnée.
   * Elle catégorise le pourcentage en différentes plages et assigne une couleur spécifique
   * à chaque plage.
   * 
   * @param {number} percentage_c - La valeur du pourcentage de conformité.
   * @param {number} percentage_not_c - La valeur du pourcentage de non conformité.
   * @returns {string} Le code couleur correspondant au pourcentage donné.
   */
  private colorsQuality(percentage_c: number, percentage_not_c: number): string {
    switch (true) {
      case (percentage_c === 0 && percentage_not_c === 0):
        return '#D3D3D3';
      case (percentage_c >= 0 && percentage_c <= 60):
        return '#f5365c';
      case (percentage_c > 60 && percentage_c <= 80):
        return 'orange';
      case (percentage_c > 80):
        return 'rgb(45,206,137)';
      default:
        return 'black';
    }
  }

}