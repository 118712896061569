import { Injectable } from "@angular/core";
import Keycloak from "keycloak-js";
import { environment } from "src/environments/environment";
import { User } from "../models/user";
import { AgenceId } from "../models/agencyId";
import { AgencyService } from "./agency.service";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class KeycloakService {


    // Subject pour notifier les observateurs de la déconnexion de l'utilisateur
    // Topic to notify observers of user disconnection
    private logoutSubject = new Subject<void>();

    // Observable auquel les autres services peuvent s'abonner pour réagir à la déconnexion
    // Observable that other services can subscribe to to react to disconnection
    logout$ = this.logoutSubject.asObservable();


    private _keycloack: Keycloak | undefined;
    private _profile: User | undefined;

    get keycloack(): Keycloak {
        if (!this._keycloack) {
            this._keycloack = new Keycloak({
                url: environment.keycloak,
                realm: 'Ciorem',
                clientId: 'kpi'
            });
        }
        return this._keycloack;
    }

    get profile(): User | undefined {
        return this._profile;
    }

    get token(): string | undefined {
        return this._profile?.token;
    }

    get agencies(): AgenceId[] | undefined {
        return this._profile?.agencies ? this.profile?.agencies![0] : undefined;
    }

    get profileUsername(): string | undefined {
        return this._profile?.preferred_username;
    }

    constructor(private agencyService: AgencyService) { }

    async init() {
        await this.keycloack.init({
            onLoad: 'login-required'
        }).then(async (authenticated) => {
            if (authenticated) {
                this._profile = (await this._keycloack?.loadUserInfo()) as User;
                this._profile.token = this.keycloack.token;
                this.agencyService.agencies = this._profile.agencies![0];
                this.scheduleTokenRefresh();
            }
        }).catch(() => {
            console.error("Error while authenticating");
        });
    }

    private scheduleTokenRefresh(): void {
        if (!this._keycloack) return;
        const expiresIn = (this.keycloack.tokenParsed!.exp! * 1000) - new Date().getTime();
        const refreshInterval = expiresIn - 60000;

        setTimeout(() => {
            this._keycloack!.updateToken(5000).then((refreshed) => {
                if (refreshed) {
                    this._profile!.token = this._keycloack?.token;
                }
                this.scheduleTokenRefresh();
            }).catch(() => {
                console.error('Failed to refresh token');
            });
        }, refreshInterval);
    }

    login() {
        return this.keycloack.login();
    }

    logout() {
        this.logoutSubject.next();
        return this.keycloack.logout({
            redirectUri: environment.appUrl
        });
    }
}