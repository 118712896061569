import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { differenceInDays, format } from "date-fns";
import { fr } from "date-fns/locale";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class PeriodService {

    date_debut: Date = new Date();
    date_fin: Date = new Date();
    update = 0;
    public update_counter: BehaviorSubject<number> = new BehaviorSubject<number>(this.update);
    private rangeSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(
        private datePipe: DatePipe
    ) {}

    getDateDebut(): string {
        const formattedDate = this.datePipe.transform(this.date_debut, "yyyy-MM-dd");
        return formattedDate ?? '';
    }

    getDateFin(): string {
        const formattedDate = this.datePipe.transform(this.date_fin, "yyyy-MM-dd");
        return formattedDate ?? '';
    }

    setDateDebut(date_debut: Date): void {
        this.date_debut = date_debut;
    }

    setDateFin(date_fin: Date): void {
        this.date_fin = date_fin;
    }

    /** ENG
    * Updates the start and end dates and recalculates the date range.
    * @param date_debut - The new start date.
    * @param date_fin - The new end date.
    */
    /** FR
    * Modifie les dates de début et de fin et met à jour la plage de dates.
    * @param date_debut - La nouvelle date de début.
    * @param date_fin - La nouvelle date de fin.
    */
    changeDates(date_debut: Date, date_fin: Date) {
        this.setDateDebut(date_debut);
        this.setDateFin(date_fin);
        this.update++;
        const range = differenceInDays(this.date_fin, this.date_debut) + 1;
        this.update_counter.next(this.update);
        this.rangeSubject.next(range);
    }

    /** ENG
     * Returns an observable that emits the current date range.
     * @returns An observable that emits the current date range as a number.
     */
    /** FR
     * Renvoie un observable qui émet la plage de dates actuelle.
     * @returns Un observable qui émet la plage de dates actuelle en tant que nombre.
     */
    get range$(): Observable<number> {
        return this.rangeSubject.asObservable();
    }

    getDateDebutAffichageFR(): string {
        return format(this.date_debut, 'EEEE dd MMMM yyyy', { locale: fr })
    }
    getDateFinAffichageFR(): string {
        return format(this.date_fin, 'EEEE dd MMMM yyyy', { locale: fr })
    }

    formaterDate(inputDate: string): string {
        if (!inputDate) {
            return "";
        }
        const date = new Date(inputDate);
        return this.datePipe.transform(date, 'dd/MM/yyyy') ?? '';
    }

    /**
     * Formats a date string from 'dd/MM/yyyy' to 'yyyy-MM-dd'.
     * @param inputDate The date string to be formatted.
     * @returns The formatted date string.
     */
    /**
     * Formate une chaîne de date de « jj/MM/aaaa » à « aaaa-MM-jj ».
     * @param inputDate La chaîne de date à formater.
     * @returns La chaîne de date formatée.
     */
    formaterDateSearch(inputDates: string[]): string[] {
        if (!inputDates) {
          return [];
        }
    
        return inputDates.map(inputDate => {
          const [day, month, year] = inputDate.split('/').map(part => parseInt(part, 10));
          const date = new Date(year, month - 1, day);
          return this.datePipe.transform(date, 'yyyy-MM-dd') ?? '';
        });
      }

    getDateDebutwithFormat(formatDate: string) {
        return format(this.date_debut, formatDate, { locale: fr });
    }

    getDateFinwithFormat(formatDate: string) {
        return format(this.date_fin, formatDate, { locale: fr });
    }

    formatDate(date: Date): string {
        return format(date, "yyyy-MM-dd", { locale: fr });
    }

}
