import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { KeycloakService } from "../services/keycloak.service";

@Injectable({
    providedIn: 'root'
})
export class HttpTokenInterceptor implements HttpInterceptor{

    constructor(private keycloakService: KeycloakService){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string | undefined = this.keycloakService.token;
        
        if(!token) return next.handle(req)
            
        const authRequest: HttpRequest<any> = req.clone({
            headers: new HttpHeaders({
                Authorization: `Bearer ${token}`
            })
        });
        return next.handle(authRequest);
    }
}


    

    