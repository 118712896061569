import { Injectable } from '@angular/core';
import { AgenceId } from '../models/agencyId';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {

  private _agencies: AgenceId[] = [];

  public get agencies() : AgenceId[] {
    return this._agencies;
  }
  
  public set agencies(agencies: AgenceId[]) {
    this._agencies = agencies;
  }

}